<template>
  <div
    class="form-login d-flex justify-content-center align-items-center flex-column"
  >
    <div class="logo">
      <img src="../../assets/img/Logo-CDEA.png" alt="" />
    </div>
    <div class="w-100 mb-3 px-5">
      <form v-on:submit.prevent="submitLogin()">
        <div class="form-title">CDEA管理者側ログイン</div>
        <b-alert show variant="danger" v-if="alertMessage">{{
          message
        }}</b-alert>
        <div class="d-flex flex-column form-input">
          <label for="email"></label>
          <input
            v-model="email"
            name="email"
            id="email"
            placeholder="ID"
            style="color: black"
          />
        </div>
        <div class="d-flex flex-column form-input">
          <label for="password"></label>
          <input
            v-model="password"
            type="password"
            name="password"
            id="password"
            placeholder="パスワード"
            style="color: black"
          />
        </div>
        <div class="remember-form">
          <input
            v-model="remember_me"
            type="checkbox"
            name="remember"
            id="remember"
          />
          <label class="ml-1" for="remember">ログインしたままにする</label>
        </div>
        <div class="btn-submit">
          <button type="submit">ログイン</button>
        </div>
      </form>
      <div class="d-flex justify-content-around my-3">
        <div class="d-flex justify-content-around my-3">
          <router-link :to="{ name: 'forgot password cdea' }" class="color-blue"
            >パスワードを忘れた方</router-link
          >
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      remember_me: false,
      alertMessage: false,
      message: "",
    };
  },
  created() {
    const token = localStorage.getItem(Constants.TOKEN_CDEA);
    const expires_at = localStorage.getItem(Constants.EXPIRES_AT_CDEA);
    if (token) {
      if (new Date() <= new Date(expires_at)) {
        this.$router.push({ name: "dashboard cdea" });
      }
    }
  },
  computed: {
    ...mapGetters(["pageId"]),
  },
  methods: {
    ...mapActions({ setPageId: "setPageId" }),
    setAlert() {
      this.alertMessage = true;
    },
    submitLogin() {
      if (!this.email && !this.password) return;
      this.email = this.email ? this.email.trim() : "";
      this.password = this.password ? this.password.trim() : "";
      const formLogin = {
        email: this.email,
        password: this.password,
      };
      if (this.password !== "" && this.email !== "") {
        Api.cdeaRequestServer
          .post(`/login`, formLogin)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              const { access_token, user, expires_in } = data.data;
              const { user_type } = user;
              const expires_at = new Date(
                new Date().setSeconds(
                  new Date().getSeconds() + expires_in - 600
                )
              );
              if (user_type === 0) {
                localStorage.setItem(Constants.TOKEN_CDEA, access_token);
                localStorage.setItem(Constants.EXPIRES_AT_CDEA, expires_at);
                localStorage.setItem(Constants.USER_TYPE_CDEA, user_type);
                localStorage.setItem(
                  Constants.IS_REMEMBER_CDEA,
                  this.remember_me
                );
                this.$router.push({
                  name: "dashboard cdea",
                });
              } else {
                this.message = "IDもしくはパスワードが間違っています ";
                this.setAlert();
                setTimeout(() => (this.alertMessage = false), 5000);
              }
            } else {
              this.message = "IDもしくはパスワードが間違っています ";
              this.setAlert();
              setTimeout(() => (this.alertMessage = false), 5000);
            }
          })
          .catch((error) => {
            this.message = error.message;
            this.setAlert();
            setTimeout(() => (this.alertMessage = false), 5000);
          });
      } else {
        this.message = "メールアドレスまたはパスワードを入力してください";
        this.setAlert();
        setTimeout(() => (this.alertMessage = false), 5000);
      }
    },
  },
};
</script>
<style lang="scss">
.form-login {
  width: 540px;
  margin: auto;
  background-color: hsla(0, 0%, 100%, 0.8) !important;
  padding: 20px;
  border-radius: 10px;
}
</style>
